import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
daEventCenter.addSubscriber({ modulecode: '2-11' })

// 遍历券包优惠券，提取券包人群id
function crowdEventLabel ({ coupons, type = 'ga', isTheme }) {
  if (!coupons || !coupons.length) return ''
  let result = []
  coupons.forEach((item, index, arr) => {
    if (type == 'ga' || type == 'sc') {
      if (index == 0 || index > 0 && item.crowd != arr[index - 1].crowd) {
        const tag = type == 'ga' ? '通用券包' : '通用券'
        result.push(item.crowd || tag)
      }
    } else if (type == 'bi') {
      if (item) {
        result.push((index + 1) + '`' + (item.crowd || '通用券'))
      }
    }
  })
  result = result.join(type == 'ga' || type == 'sc' ? '-' : ',')
  if (type == 'ga' || type == 'sc') result += isTheme ? '&自定义样式' : '&默认样式'
  return result
}

// 是否是专题页
function isActivity () {
  return gbCommonInfo && (gbCommonInfo.tabActive === 'activity' || gbCommonInfo.tabActive === 'campaigns')
}

// 日期处理
function formatDate (date) {
  return Number(date) == date
    ? date
    : (new Date(date?.replaceAll('-', '/')).getTime()) / 1000
}

function isShowCoutdown (item) {
  if (!window._SHOW_COUPON_COUNTDOWN_) return false
  const { selfStatus, timeStatus } = item
  const end_date = formatDate(item.endTime)
  // 即将开始
  if (selfStatus === '0' && timeStatus == '1') return false
  let [
    timeStep,
    newDate,
    endDate
  ] = [
    72 * 60 * 60 * 1000, //倒计时72小时
    new Date().getTime(), // 当前时间
    end_date * 1000 // 有效期结束时间
  ]
  // 不在倒计时范围内
  if (newDate + timeStep <= endDate) return false
  return true
}

// 构建ccc埋点sa参数
async function buildSaParamWithCCC (config = {}, { jumpType = 0, coupons = [], act_id = '' }) {
  if (!config) return {}
  if (typeof SaPageInfo == 'undefined') return {}
  const page_id = SaPageInfo.page_id || ''
  const { scene_id = 0, scene_name = 0, oper_id = 0, style_id = 1, id } = config
  const tag_id = isLogin() ? '50000' : '50001'
  //          页面ID`    tab-ID`场景ID_场景类型`         模板ID`区块ID`运营位ID`运营位位置ID`样式ID`人群ID`坑位ID`热区ID`层级ID`跳转类型ID
  const spm = `${page_id}\`0\`${scene_id}_${scene_name}\`0\`0\`${oper_id}\`1\`${style_id}\`${tag_id}\`0\`0\`0\`${jumpType}\`${id}`
  let content_list = coupons.reduce((result, value, index, arr) => result += `${value.couponId}_${isShowCoutdown(value) ? 'countdown' : 'interval'}` + (index == arr.length - 1 ? '' : '`'), '')
  const saParam = {
    ...config,
    spm,
    act_id,
    content_list,
    abtest: window._SHOW_COUPON_COUNTDOWN_ABTEST_ ? window._SHOW_COUPON_COUNTDOWN_ABTEST_ : '-`-`-',
    from: isActivity() ? 1 : 0, // from -> 来自专题上报1，否则上报0
    crowd_id: crowdEventLabel({ coupons, type: 'bi' })
  }
  return saParam
}

export const GB_BI_COMMON_COUPON_PACKAGE = {
  async expose_pop_coupon ({ coupons, _config_, type }) {
    try {
      const _params = await buildSaParamWithCCC(_config_, { coupons })
      _params.type = type
      sa('send', {
        activity_name: 'expose_popup',
        activity_param: _params
      })
    } catch (e) {
      console.log(e)
    }
  },
  async click_pop_coupon_close ({ coupons, _config_ }) {
    try {
      const _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 0 })
      sa('send', {
        activity_name: 'click_popup',
        activity_param: _params
      })
    } catch (e) {
      console.log(e)
    }
  },
  async click_pop_coupon_receive ({ coupons, _config_ }) {
    try {
      const _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 1 })
      sa('send', {
        activity_name: 'click_popup',
        activity_param: _params
      })
    } catch (e) {
      console.log(e)
    }
  },
  async click_pop_coupon_ok ({ coupons, _config_ }) {
    try {
      const _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 1 })
      sa('send', {
        activity_name: 'click_popup',
        activity_param: _params
      })
    } catch (e) {
      console.log(e)
    }
  },
  click_auto_coupon_get ({ status = 'success', login_type = 'logged', coupons }) {
    try {
      // let status = val ? 'success' : 'failure'
      sa('send', {
        activity_name: 'click_auto_coupon_get',
        activity_param: {
          status,
          login_type,
          coupon_crowd_id: crowdEventLabel({ coupons, type: 'bi' }),
          from: isActivity() ? 1 : 0, // from -> 来自专题上报1，否则上报0
        }
      })
    } catch (e) {
      console.log(e)
    }
  },
  async click_login_back_get ({ coupons, _config_ }) {
    const _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 1 })
    sa('send', {
      activity_name: 'click_popup',
      activity_param: _params
    })
  },
  async click_coupon_anasysis({ coupons, _config_ }) {
    let _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 2 })
    sa('send', {
      activity_name: 'click_popup',
      activity_param: _params,
    })
  },
  async click_pop_link_to_sc ({ coupons, _config_ }) {
    try {
      const _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 3 })
      sa('send', {
        activity_name: 'click_popup',
        activity_param: _params
      })
    } catch (e) {
      console.log(e)
    }
  },
  async click_coupon_expansion_btn({ coupons, _config_ }) {
    let _params = await buildSaParamWithCCC(_config_, { coupons, act_id: 5 })
    sa('send', {
      activity_name: 'click_popup',
      activity_param: _params,
    })
  },
  expose_popup_coupon_collection_failed({ type }) {
    sa('send', {
      activity_name: 'expose_popup_coupon_collection_failed',
      activity_param: {
        type
      },
    })
  },
  click_coupongotten_popup({ actId }) {
    sa('send', {
      activity_name: 'click_coupongotten_popup',
      activity_param: {
        act_id: actId
      },
    })
  },
  expose_usebutton_hand() {
    sa('send', {
      activity_name: 'expose_usebutton_hand',
    })
  }
}

export const GB_All_COMMON_COUPON_PACKAGE = {
  click_success_check_coupons({ coupons, isTheme, button_content }) {
    daEventCenter.triggerNotice({
      daId: '2-11-5',
      extraData: {
        coupon_content: crowdEventLabel({ coupons, type: 'sc', isTheme }),
        is_login: isLogin() ? true : false,
        button_content
      }
    })
  },
  click_failed_check_coupons({ coupons, isTheme, button_content }) {
    daEventCenter.triggerNotice({
      daId: '2-11-6',
      extraData: {
        coupon_content: crowdEventLabel({ coupons, type: 'sc', isTheme }),
        is_login: isLogin() ? true : false,
        button_content
      }
    })
  },
  expose_popup_coupon_collection_failed() {
    daEventCenter.triggerNotice({
      daId: '2-11-7'
    })
  },
  click_pop_coupon_close() {
    daEventCenter.triggerNotice({
      daId: '2-11-8'
    })
  },
  click_pop_coupon_ok({ coupons, isTheme, button_content }) {
    daEventCenter.triggerNotice({
      daId: '2-11-9',
      extraData: {
        coupon_content: crowdEventLabel({ coupons, type: 'sc', isTheme }),
        is_login: isLogin() ? true : false,
        button_content
      }
    })
  }
}

export const COUPON_MONITOR = {
  // 常规监控
  normaMonitor(params = {}) {
    expose_coupon_monitor(0, params)
  },
  // 异常监控
  errorMonitor(params = {}) {
    expose_coupon_monitor(1, params)
  },
  // 业务逻辑监控
  operationMonitor(params = {}) {
    expose_coupon_monitor(2, params)
  },
}

// 券包监控事件
export const expose_coupon_monitor = (monitor_type, params = {}) => {
  const { code, failure_reason, show_judge = 2, failure_port = '-', coupon_package_id = '-', remark = '-', show_time } = params
  delete params.code
  const reason = typeof failure_reason === 'string' ? failure_reason : JSON.stringify(failure_reason)
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'common/couponPackage/old' })
  const code_branch_remark = {
    mid: memberId || '-',
    cid: UserInfoManager.get({ key: 'oest' }) || '-',
    remark,
  }
  
  daEventCenter.triggerNotice({
    daId: '2-11-16',
    extraData: {
      monitor_type,
      code_branch: code,
      failure_reason: reason || '-',
      show_judge,
      code_branch_remark,
      failure_port,
      coupon_package_id,
      show_time,
    }
  })
}
