import { removeLocalStorage } from '@shein/common-function'
import { getSite } from './ip_jump_dialog/sites'

const CACHE_MAP = {
  have_show: 'hideRegisterPop',
  hideCoupon: 'couponPkgPopHide',
  hideCouponId_time: 'couponPkgPopHide_id_time',
  hideCoupon_type: 'couponPkgPopHide_type'
}

CACHE_MAP[`no_pop_up_m`] = `ret_dir_cache_m${getSite()}`

try {
  for (let key in CACHE_MAP) {
    const cache = localStorage.getItem(key)
    if (cache) {
      removeLocalStorage(key)
      localStorage.setItem(CACHE_MAP[key], cache)
      console.log('旧缓存已经删除, key:', key)
    }
  }
} catch (error) {
  console.log('error', error)
}
