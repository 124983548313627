export const sitesSHEIN = {
  m: '//m.shein.com',
  us: '//m.shein.com/us',
  uk: '//m.shein.com/uk',
  de: '//m.shein.com/de',
  fr: '//m.shein.com/fr',
  es: '//m.shein.com/es',
  it: '//m.shein.com/it',
  au: '//m.shein.com/au',
  ru: '//m.shein.com/ru',
  ar: '//m.shein.com/ar',
  aren: '//m.shein.com/ar-en',
  in: '//m.shein.in',
  tw: '//m.shein.tw',
  ca: '//m.shein.com/ca',
  se: '//m.shein.se',
  mx: '//m.shein.com.mx',
  il: '//m.shein.com/il',
  th: '//m.shein.com/th',
  eur: '//m.shein.com/eur',
  nl: '//m.shein.com/nl',
  hk: '//m.shein.com.hk',
  vn: '//m.shein.com.vn',
  cl: '//m.shein.com/cl',
  ma: '//m.shein.com/ma',
  br: '//m.shein.com/br'
}

export const sitesRW = {
  m: '//m.romwe.com',
  us: '//m.romwe.com/us',
  uk: '//m.romwe.com/uk',
  de: '//m.romwe.com/de',
  fr: '//m.romwe.com/fr',
  es: '//m.romwe.com/es',
  it: '//m.romwe.com/it',
  au: '//m.romwe.com/au',
  ca: '//m.romwe.com/ca',
  ar: '//m.romwe.com/ar',
  in: '//m.romwe.co.in',
  mx: '//m.romwe.com/mx'
}

export const getSite = () => {
  const { lang } = gbCommonInfo
  let site = ''
  switch (lang) {
    case 'ar-en':
      site = 'aren'
      break
    case 'ilen':
      site = 'il'
      break
    case 'then':
      site = 'th'
      break
    case 'cafr':
      site = 'ca'
      break
    case 'sesv':
      site = 'se'
      break
    case 'eurfr':
    case 'eurde':
      site = 'eur'
      break
    case 'eurnl':
    case 'eurpt-pt':
      site = 'eur'
      break
    case 'eurbg':
      site = 'eur'
      break
    case 'uses':
      site = 'us'
      break
    case 'enes':
      site = 'en'
      break
    case 'enpt-pt':
      site = 'en'
      break
    case 'jpen':
      site = 'jp'
      break
    case 'kren':
      site = 'kr'
      break
    case 'euqscs-cz':
      site = 'euqs'
      break
    case 'euqsel-gr':
      site = 'euqs'
      break
    case 'euqsfr':
      site = 'euqs'
      break
    case 'euqsnl':
      site = 'euqs'
      break
    case 'euqssk':
      site = 'euqs'
      break
    case 'euqshu':
      site = 'euqs'
      break
    default:
      site = lang
      break
  }
  return site
}
