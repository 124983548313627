import './libs_env_injection'
import './sw'
import './shein_common/core'
import '../../services/TPM'

import './globalCheck'

import schttp from '../../services/schttp'
import './ugidHandle'
import './variable'
import './common_cookie_manager'
// 站点全局业务代码
import './mdmpUserLanding'
import './header'
import './scroll_top'
import { pageView } from './analysis'
import '../login/asyncIndex'
import '../signup_fast/asyncIndex'
import '../components/globalPrivacy/asyncIndex'
import { abtservice } from 'public/src/services/abt'
import './devices'
import './infp'
import './performance'
import { Drawer, Loading } from '@shein/sui-mobile'
import './deviceFingerprint/send' // 兼容性数据回收
import { parseQueryString } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import './abtPageAll'
// import './encrypt'
import './removeCache'
import { loadGlobalPopModule } from 'public/src/pages/common/glob_popup_modal'
import { markPoint } from 'public/src/services/mark/index.js'
import './monitor'

const { PUBLIC_CDN, lang, WEB_CLIENT, SiteUID, NODE_SERVER_ENV } = gbCommonInfo
window.__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

Vue.config.performance = NODE_SERVER_ENV !== 'production'
Vue.component(Drawer.name, Drawer)
Vue.component(Loading.name, Loading)

// pv事件
// 新增 params 入参
appEventCenter.$on('pageOnload', (params = {}) => {
  pageView(params)
  if ($('.waves-btn-click').length) $('.waves-btn-click').remove()
})

$(() => {

  loadGlobalPopModule()
  
  requestIdleCallback(() => {
    $(document).on('click', '.waves-effect', function () {
      $(this).append('<div class="waves-btn-click"></div>').find('.waves-btn-click').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        $(this).remove()
      })
    })

    //更新csrf_token
    schttp.updateXCsrfToken()

    // init fastclick
    // eslint-disable-next-line no-undef
    FastClick.attach(document.body)
    // eslint-disable-next-line no-undef
    FastClick.prototype.focus = function (targetElement) {
      targetElement.focus()
    }
  })
})

$(window).on('load', () => {
  requestIdleCallback(() => {
    // es跳转mx
    if (lang === 'es') {
      import('./es_jump_mx')
    }
    if (SiteUID === 'pwus' && WEB_CLIENT === 'shein') {
      import('./attentive/index')
    }
  })
  !isLogin() && import('./googleOneTab')
  isLogin() && import('./bindMessenger.js')

  const { needPopLogin } = parseQueryString(location.search)
  if (needPopLogin == 1 && !isLogin()) {
    SHEIN_LOGIN.show({ show: true })
  }
})

markPoint({ eventName: 'commonJsLoaded', measureTo: 'productAppJsLoaded' })
