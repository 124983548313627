const { PUBLIC_CDN, SA_SDK_CONFIG, SiteUID, PERSONALIZED_SWITCH = {} } = gbCommonInfo
// const SA_SDK_CONFIG = {
//   loading: true, // 是否引入新SDK
//   sampling: true, // 是否开启采样
// }

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import manifest from './file-manifest'
const { sdk_analysis } = manifest

export class SheinAnalytics {
  static name = 'SheinAnalytics'

  init() {
    // 个性化开关
    const showInfo = PERSONALIZED_SWITCH?.[SiteUID] ?? 1
    // 添加埋点拦截器
    // async 不能删掉 ！！！一定要 promise 返回，否则会有大问题！！！
    window.analysisBeforeInterceptor.use(async function (result) {
      // result == { data: {} }
      try {
        if (result.data) {
          const [currency, country, memberId, ugid] = await Promise.all([
            UserInfoManager.get({ key: 'currency' }),
            UserInfoManager.get({ key: 'Country' }),
            UserInfoManager.get({ key: 'memberId' }),
            UserInfoManager.get({ key: 'ugid' }),
          ])
          Object.assign(result.data, {
            member_id: memberId,
            login: Number(Boolean(memberId)),
            money_type: currency,
            device_country: country && country.formatCountryAbbr || '', // eslint-disable-line
            showInfo,
            ugid,
          })
        }
        return result
      } catch (e) {
        return result
      }
    })
    
    const scrips = []
    // 旧SDK
    // 如果没有引入新SDK || 引入新SDK且开启采样（双通道）
    if (!SA_SDK_CONFIG.loading || SA_SDK_CONFIG.loading && SA_SDK_CONFIG.sampling) {
      scrips.push({
        TPM_Ele_Type: 'script',
        TPM_Config: {
          CDN: true
        },
        src: `${PUBLIC_CDN}/pwa_dist/js/${sdk_analysis}`,
      })
    }
    // 新SDK
    if (SA_SDK_CONFIG.loading) {
      // 需要采样（双通道上报），否则全量替换旧 SDK
      // 双通道需要给一个新的变量，这个变量将会在旧 SDK 中使用
      if (SA_SDK_CONFIG.sampling) {
        window.web_sa_fix = function() {
          if (!window.web_sa_fix.waitingCommands) window.web_sa_fix.waitingCommands = []
          window.web_sa_fix.waitingCommands.push(arguments)
        }
      }
      scrips.push({
        TPM_Ele_Type: 'script',
        TPM_Config: {
          CDN: true
        },
        src: '//cjs.ltwebstatic.com/unpkg/@shein-aidc/basis-sa@1.1.12/dist/web-sa.umd.js'
      })
    }
    return scrips
  }
}
