import { getIsSupportMark, getMarkEngineType, apiWhiteList, jsWhiteList } from 'public/src/services/mark/index.js'
const { WEB_CLIENT, SiteUID, appLanguage, REPORT_URL } = gbCommonInfo

export class ClientAnalytics {
  static name = 'ClientAnalytics'

  #IFS = ['init', 'registerPage', 'track', 'defineTrack']

  constructor() {
    function CA(_) {
      return function () {
        (CA._q = CA._q || []).push([_, arguments])
      }
    }

    this.#IFS.forEach(_ => CA[_] = CA.call(null, _))
    window['sheinClientAnalytic202110'] = 'clientAnalytics'
    window.clientAnalytics = this.CA = CA
  }

  init() {
    return async () => {
      const module = await import('@shein/client-analytics-sdk')
      if (!module || !module.default) return
      this.CA = module.default

      let timer = null
      let count = 0

      // 注:
      // 1. 打点功能在设置 cookie vueDebug=1 时开 SDK 使用 performance.mark 进行打点（自动化测试场景下使用并收集相关数据）
      // 2. 打点功能在设置 cookie performanceTest=1 时, 使用 Date.now 进行打点, 打点信息仅收集不上报, 测试可通过性能页面查看相关数据
      // 3. 常规情况下 SDK 改为使用 Date.now 进行打点，因为 performance.mark 会影响页面性能, 并上报相关数据为后续APM看板分析做准备
      const isSupportMark = getIsSupportMark()
      const markEngineType = getMarkEngineType()
      this.CA.init({
        reportUrl: `${REPORT_URL.SA_REPORT_URL}/clientPerInfo`,
        random: 1,
        performanceTargetCollectConfig: {
          isSupportMark, // 是否支持打点并启用SDK
          isDebug: false,
          markEngineType,
          apiWhiteList,
          jsWhiteList,
        },
      })

      this.CA.registerPage({
        device_type: 'pwa',         // 平台
        sub_site: SiteUID,          // 站点
        language: appLanguage,      // 语言
        home_site: WEB_CLIENT,      // 品牌
        page_name: function () {
          return window?.SaPageInfo?.page_name || 'other'
        }
      })

      if (isSupportMark) {
        this.CA?.PerformanceTargetCollectInner?.observeRouteChange?.(_gb_app_?._router)
      }

      timer = setInterval(() => {
        count += 1
        // 如果有设置了值或者已经等了5秒了
        if (window?.SaPageInfo?.page_name || count > 5) {
          clearInterval(timer)
          timer = null
          //开启采集
          this.CA.track({
            resource: {
              random: 0.05,
            }
          })
        }
      }, 1000)

      // 仅在拥有实验室标识时异步加载对应入口
      if (markEngineType === 'testDateNow') {
        // 延迟 3s 加载实验室上报入口, 避免影响业务性能
        setTimeout(() => {
          import('public/src/pages/common/laboratory/index.js').then(res => {
            res?.laboratoryController?.insert?.()
          })
        }, 1000 * 3)
      }
    }
  }

  _defineTrack({ data, options = {} }) {
    this.CA.defineTrack(data, options)
  }
}
